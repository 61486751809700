<template>
    <b-overlay :show="showLoader">
        <b-card :title="$t('invoices.issuing_invoice')">
            <first-step-invoice @turnOnLoader="showLoader=true" @turnOffLoader="showLoader=false" v-if="step === 0" @finishFirstStep="increaseStep"></first-step-invoice>
            <second-step-invoice v-else-if="step === 1" :invoice="invoice"></second-step-invoice>
        </b-card>
    </b-overlay>
</template>

<script>
    import { BCard, BOverlay } from 'bootstrap-vue'
    import FirstStepInvoice from '@/views/Invoices/Issued/components/FirstStepInvoice.vue'
    import SecondStepInvoice from '@/views/Invoices/Issued/components/SecondStepInvoice.vue'

    export default {
        components:{ FirstStepInvoice, SecondStepInvoice, BCard, BOverlay },
        data() {
            return {
                step: 0,
                invoice: {},
                showLoader: false
            }
        },
        methods: {
            increaseStep(createdInvoice) {
                this.invoice = createdInvoice
                this.step += 1
            }
        }
    }
</script>

<style lang="scss" scoped>
</style>